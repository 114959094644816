// Import statements
import $ from "jquery";
window.jQuery = $;
window.$ = $;
require("service_workers");

import ClipboardJS from "clipboard";
import { Notyf } from "notyf";
import lazySizes from "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";
import Swiper, { Scrollbar, Autoplay } from "swiper";
import Masonry from "masonry-layout";
import MmenuLight from "mmenu-light";
import mailcheck from "mailcheck";
require("application/story.js");

// Additional imports to ensure feature parity
require("@rails/ujs").start();
require("bootstrap/js/dist/modal.js");
require("bootstrap/js/dist/tooltip.js");
require("bootstrap/js/dist/alert.js");
require("@fancyapps/fancybox/dist/jquery.fancybox.min.js");
require("vegas");

// Initialize global variables

window.__swiperInstance = {};
window.localForage = window.localStorage;
window.__prevGirls = [];

// Lazy loading configuration
lazySizes.cfg.loadMode = 1;

// Toastr configuration
const toastr = new Notyf({
  dismissible: true,
  duration: 8000,
  position: { x: "center", y: "top" },
});
window.notyf = toastr;
window.toastr = toastr;

// Utility functions
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function rand(items) {
  shuffleArray(items);
  return items[0];
}

function scrollBottom(offset = 3) {
  return (
    $(window).scrollTop() + $(window).height() >
    $(document).height() - $(document).height() / offset
  );
}

// Main functions
window.initSwiper = function (el) {
  document.querySelectorAll(".swiper").forEach((el) => {
    window.__swiperInstance[el.dataset.postid] = new Swiper(el, {
      modules: [Scrollbar, Autoplay],
      loop: true,
      grabCursor: true,
      autoplay: false,
      preloadImages: false,
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
      },
    });
  });
};

window.autoplayViewPort = function (elements) {
  const $arr = elements
    ? $(elements)[0].querySelectorAll(".swiper")
    : document.querySelectorAll(".swiper");

  $arr.forEach((el) => {
    let observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && $(window).width() < 560) {
            window.__swiperInstance[el.dataset.postid].autoplay.start();
          } else {
            window.__swiperInstance[el.dataset.postid].autoplay.stop();
          }
        });
      },
      { threshold: 0.6 }
    );
    observer.observe(el);
  });
};

function initStorage() {
  if (localStorage.length > 0) {
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        if (
          key.match(
            /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
          )
        ) {
          try {
            let val = localStorage.getItem(key);
            let _parsed = JSON.parse(val);
            const item = `<div id="${key}" class="fav-item col-3" data-fancybox data-slide-class='card-profile-fancybox' data-type="ajax" data-src="${_parsed.src}"><i class="fa fa-times-circle remove-fav"></i><img src='${_parsed.imgSrc}' class="image-fluid" /></div>`;
            $(".favourite-list .inner").append(item);
          } catch (e) {
            console.log(e);
            console.log("error with fav");
          }
        }
      }
    }
  }
}

function initMenu() {
  if ($("#my-menu").length !== 0) {
    const menu = new MmenuLight(document.querySelector("#my-menu"));
    const navigator = menu.navigation({ title: "Hush Escort" });
    const drawer = menu.offcanvas();
    window.__drawer = drawer;
    document
      .querySelector("a[href='#my-menu']")
      .addEventListener("click", (evnt) => {
        drawer.open();
      });
  }
}

function loadMapView() {
  var mapPlaceholder = $("#map-placeholder");
  if (mapPlaceholder.length) {
    var parentInnerWidth = mapPlaceholder.parent().innerWidth();
    var containerWidth = parentInnerWidth;
    var lat = mapPlaceholder.data("lat");
    var lng = mapPlaceholder.data("lng");
    var iframeHtml = `<iframe width="${containerWidth}" height="400" frameborder="0" id="provider-map"
      src="https://www.bing.com/maps/embed?h=400&w=${containerWidth}&cp=${lat}~${lng}&lvl=15&typ=d&sty=r&src=SHELL&FORM=MBEDV8"
      scrolling="no"></iframe>`;
    mapPlaceholder.html(iframeHtml);
  }
}

function loadSearchModal(cb) {
  let el = document.getElementById("global-content-modal");
  if (!el) {
    $.ajax({
      url: "/api/search_modal",
      type: "GET",
      headers: {
        Authorization: "Bearer DyZYY5q5sW8YTH4RSwFXNdt3Dx2LedCVNHpCBGn4",
        type: "GET",
        contentType: "application/json",
      },
    })
      .done(function (content) {
        $(content).appendTo(document.body);
        cb();
      })
      .fail((error) => {
        notyf.error("no se puede cargar la búsqueda, actualice la página");
      });
  } else {
    cb();
  }
}

// Event handlers and initializations
$(document).ready(function () {
  $(".alert").alert();
  if ($(".require-vegas").length > 0) {
    window.loadVegas();
  }
  setupInfiniteScroll();

  initClip();
  $("[data-fancybox]").fancybox({
    afterShow: function () {
      loadMapView();
    },
  });
  if (document.getElementById("map-placeholder")) {
    loadMapView();
  }
  window.loadAcceptModal();
  if (
    $("body").hasClass("welcome index") ||
    $("body").hasClass("welcome search") ||
    $("body").hasClass("welcome tag")
  ) {
    window.intervalTimer = setTimeout(function (args) {
      window.loadSubscribeModal();
    }, 60000);
  }

  initMenu();

  if ($("#grid-container").length > 0) {
    var windowWidth = $(window).width();
    if (windowWidth > 480) {
      let msnry = new Masonry("#grid-container", {
        itemSelector: ".grid-item",
        columnWidth: ".grid-sizer",
        percentPosition: true,
      });
      window.msnry = msnry;
    }
  }

  setTimeout(() => {
    window.initSwiper();
    window.autoplayViewPort();
    window.lazyLoadLustgram(".search-section .result");
  }, 0);

  initStorage();

  // Event delegation
  $(document).on("click", ".post-link", function (e) {
    e.preventDefault();
  });

  $(document).on("click", ".remove-fav", function (e) {
    e.stopPropagation();
    let key = $(this).parent().attr("id");
    $(this).parent().remove();
    localStorage.removeItem(`${key}`);
  });

  $(".header, .close-fav, .fav-tab").click(function () {
    $(".favourite-list").slideToggle("fast", function () {});
  });

  $(document).on("click", ".fav-btn", function (e) {
    $(this).toggleClass("liked");
    const key = $(this).data("id");

    if ($(".favourite-list .inner").children(`#${key}`).length > 0) {
      $(".favourite-list .inner").children(`#${key}`).remove();
      localStorage.removeItem(`${key}`);
    } else {
      const imgSrc = $(this).data("img");
      const src = $(this).data("path");
      const item = `<div id="${key}" class="fav-item col-3" data-fancybox data-slide-class='card-profile-fancybox' data-type="ajax" data-src="${src}"><i class="fa fa-times-circle remove-fav"></i><img src='${imgSrc}' class="image-fluid" /></div>`;
      $(".favourite-list .inner").append(item);
      toastr.success("añadido a tus favoritos!");
      localStorage.setItem(
        key,
        JSON.stringify({
          src,
          imgSrc,
        })
      );
    }
  });

  $(document).on("click", ".report-post", function () {
    let successTitle = $(this).data("success");
    window.toastr.success(successTitle);
  });

  $(".call-global-modal").click(function (e) {
    let loadModal = () => $("#global-content-modal").modal("toggle");
    window.loadSearchModal(loadModal);
  });

  // Infinite scrolling
  function setupInfiniteScroll() {
    if ($(".pagination").length) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadNextPage();
          }
        },
        { rootMargin: "100px" }
      );

      observer.observe(document.querySelector(".pagination"));
    }
  }

  function loadNextPage() {
    var url = $(".page.next a").attr("href");
    if (url) {
      console.log("fetching next page");
      $(".pagination").html(
        '<div class="ajax-loader mx-auto mt-5 mb-5"><i class="fa fa-spinner fa-pulse fa-fw fa-2x"></i><span class="sr-only">Loading...</span></div>'
      );
      $.getScript(url)
        .done(function (script, textStatus, jqxhr) {
          if (typeof window.initSwiper === "function") {
            window.initSwiper();
            window.autoplayViewPort();
          } else {
            console.error("initSwiper is not a function");
          }
          // Re-setup the infinite scroll after loading new content
          setupInfiniteScroll();
        })
        .fail(function (jqxhr, settings, exception) {
          console.error("Error loading script:", exception);
          // Restore pagination in case of error
          $(".pagination").html(
            '<div class="page next"><a href="' + url + '">Next</a></div>'
          );
          setupInfiniteScroll();
        });
    }
  }

  // Previous content loading
  if ($(".prev-content").length > 0) {
    const observer = new IntersectionObserver(
      debounce(() => {
        let url = rand(
          $(".other-girls-container")
            .last()
            .children()
            .map((i, d) => $(d).attr("href"))
        );

        if (url) {
          $(".next-loader")
            .last()
            .show()
            .html(
              '<div class="ajax-loader"><i class="fa fa-spinner fa-pulse fa-fw fa-2x"></i><span class="sr-only"></span><p>cargando el siguiente perfil...</p></div>'
            );
          return $.getScript(url);
        }
      }, 1000),
      { rootMargin: "200px" }
    );

    observer.observe(document.querySelector(".prev-content"));
  }
});

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function initClip() {
  let clipboard = new ClipboardJS(".get-link", {});

  clipboard.on("success", function (e) {
    toastr.success("enlace copiado!");
  });
  clipboard.on("error", function (e) {
    console.error("Action:", e.action);
    console.error("Trigger:", e.trigger);
  });
}

window.initClip = initClip;
window.loadSubscribeModal = function () {
  // Implementation as per original code
};
window.loadVenusModal = function () {
  let exist = window.localForage.getItem("venus_subscribe");
  if (!exist) {
    $("#venus-signup").modal("hide");
    $("#venus-signup").modal("show");
  }
};
window.loadAcceptModal = function () {
  let exist = window.localForage.getItem("accept_terms");
  if (!exist) {
    window.intervalTimer = setTimeout(function (args) {
      $("#terms-conditions").modal("show");
    }, 10000);
  }
};
window.__acceptTerms = function setTerms() {
  console.log("setting terms");
  window.localForage.setItem("accept_terms", true);
};
window.__runEmailAutoCorrect = function autoCorrectEmail(element) {
  element.addEventListener("blur", (event) => {
    mailcheck.run({
      email: element.value,
      suggested: function (suggestion) {
        element.value = suggestion.full;
      },
      empty: function () {
        // callback code
      },
    });
  });
};

window.loadVegas = () => {
  $("body").vegas({
    delay: 100000,
    timer: false,
    preload: true,
    shuffle: true,
    cover: true,
    transition: "blur2",
    slides: [
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/1.jpg",
      },
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/2.jpg",
      },
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/3.jpg",
      },
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/4.jpg",
      },
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/5.jpg",
      },
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/6.jpg",
      },
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/7.jpg",
      },
      {
        src: "https://res.cloudinary.com/everyinch/image/upload/b_white,o_55,e_gradient_fade,x_0.99,f_auto/v1452281150/bg_img/logins/8.jpg",
      },
    ],
  });
};

window.lazyLoadLustgram = function (contentSelector) {
  var aTags = document.querySelectorAll(contentSelector + " a:not(.loaded)");
  aTags.forEach(function (aTag) {
    var img = aTag.querySelector("img[data-lg-src]:not(.loaded)");
    if (img) {
      var dataSrc = img.dataset.lgSrc;
      fetch(dataSrc)
        .then((response) => response.json())
        .then((data) => {
          var { link, asset } = data;

          // Load the image
          var sourceSet = asset
            .map((url, index) => `${url} ${index + 1}x`)
            .join(", ");
          img.srcset = sourceSet;
          img.src = asset[0]; // fallback src

          // Update the href of the parent
          aTag.href = link + "?utm_medium=user_profile";
          img.classList.add("loaded");
          aTag.classList.add("loaded");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  });
};

window.lazyLoadLustgram = lazyLoadLustgram;
