if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    setTimeout(() => {
      navigator.serviceWorker.register('/service-worker.js', { scope: "/" })
        .then(function (registration) {
          console.log('[ServiceWorker Client]', 'registration successful with scope: ', registration.scope);

          registration.addEventListener('updatefound', function () {
            caches.open('assets-styles-and-scripts').then(function (cache) {
              let stylesheetLinks = document.querySelectorAll('link[rel="stylesheet"][href^="/"]');
              cache.addAll(Array.from(stylesheetLinks).map(elem => elem.getAttribute("href")));

              let scriptLinks = document.querySelectorAll('script[src^="/"]');
              let linkCache = Array.from(scriptLinks).map(elem => elem.getAttribute("src")).filter((el) => {
                return !el.includes('maps')
              })
              cache.addAll(linkCache);
            });
          });

        }).catch(function (err) {
          console.log('[ServiceWorker Client]', 'registration failed: ', err);
        });
    }, 0);

  });
}

function uniqueArray4(a) {
  return [...new Set(a)];
}