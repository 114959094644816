var timestamp = function () {
    var timeIndex = 0;
    var shifts = [35, 60, 60 * 3, 60 * 60 * 2, 60 * 60 * 25, 60 * 60 * 24 * 4, 60 * 60 * 24 * 10];
    var now = new Date();
    var shift = shifts[timeIndex++] || 0;
    var date = new Date(now - shift * 1000);
    return date.getTime() / 1000;
};

function transformData({ data }) {
    return data.map(function (d) {
        return Object.assign(d.attributes, { lastUpdated: timestamp() })
    })
}

import ZuckJS from 'zuck.js'
$(document).ready(function () {
    if ($('#stories').length > 0) {
        setTimeout(() => {
            $.ajax({
                url: '/api/stories',
                headers: {
                    Authorization: "Bearer DyZYY5q5sW8YTH4RSwFXNdt3Dx2LedCVNHpCBGn4",
                    type: 'GET',
                    contentType: "application/json",
                }
            })
                .done(function (data) {
                    $(window).scrollTop()
                    new ZuckJS('stories', {
                        skin: 'snapgram', // container class
                        avatars: true, // shows user photo instead of last story item preview
                        list: false, // displays a timeline instead of carousel
                        openEffect: false, // enables effect when opening story
                        cubeEffect: false, // enables the 3d cube effect when sliding story
                        autoFullScreen: true, // enables fullscreen on mobile browsers
                        backButton: true, // adds a back button to close the story viewer
                        backNative: true, // uses window history to enable back button on browsers/android
                        previousTap: true, // use 1/3 of the screen to navigate to previous item when tap the story
                        localStorage: true, // set true to save "seen" position. Element must have a id to save properly.
                        reactive: false,
                        stories: transformData(data),
                        callbacks: {
                            onClose(storyId, callback) {
                                window.loadSubscribeModal()
                                callback(); // on close story viewer
                            },
                        },
                        language: { // if you need to translate :)
                            unmute: '',
                            keyboardTip: 'Presione la barra espaciadora para ver el siguiente',
                            visitLink: 'Ven a verme .. mi rey',
                            time: {
                                ago: 'hace',
                                hour: 'hora',
                                hours: 'horas',
                                minute: 'minuto',
                                minutes: 'minutos',
                                fromnow: 'from now',
                                seconds: 'segundos',
                                yesterday: 'ayer',
                                tomorrow: 'mañana',
                                days: 'dias'
                            }
                        }
                    });
                })
                .fail(function () {
                    console.log('failed to get story')
                })
        }, 0);


    }
})